import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/layout'
import PrevNext  from '../components/prevNext';
import { Container } from 'react-bootstrap';

const Post = (props) => {
  return (
    <Layout>
      <Container className="my-3">
        <Seo title={props.data.markdownRemark.frontmatter.title} />
        <h1>{props.data.markdownRemark.frontmatter.title}</h1>
        <div className="text-secondary"><span className="me-2">更新日</span>{ props.data.markdownRemark.frontmatter.updated_at }</div>
        <div className="text-secondary"><span className="me-2">投稿日</span>{ props.data.markdownRemark.frontmatter.created_at }</div>
        <div className="text-secondary"><span className="me-4">タグ</span>{ props.data.markdownRemark.frontmatter.tags.join(', ') }</div>
        <div className="text-secondary"><span className="me-2">投稿者</span> { props.data.markdownRemark.frontmatter.author }</div>
        <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} className="my-5" />
        <PrevNext pageContext={props.pageContext} />
      </Container>
    </Layout>
  )
}

export default Post;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        updated_at
        created_at
        tags
        author
      }
    }
  }
`