import * as React from 'react';
import { Link } from 'gatsby';
// import { Row, Col } from 'react-bootstrap';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PrevNext =({ pageContext }) => {

  const { previous, next } = pageContext;

  const getSlug = (path) => {
    const splitPath = path.match(/(\/posts\/)([^\/]+\/)([^\/]+\/)/);
    return splitPath ? `${splitPath[1]}${splitPath[3]}` : path;
  }
  
  return (
    <div className="row my-5">
      <div className="col-3 text-start">
        {previous && 
          <Link to={`${getSlug(previous.fields.slug)}`} className="text-start text-secondary text-decoration-none">
            <FontAwesomeIcon className="fa-fw fa-lg text-secondary" icon={faAngleDoubleLeft}></FontAwesomeIcon>
            Prev
          </Link>
        }
      </div>
      <div className="col-6 text-center">
        <Link to="/" className="text-secondary text-decoration-none">記事一覧に戻る</Link>
      </div>
      <div className="col-3 text-end">
        {next && 
          <Link to={`${getSlug(next.fields.slug)}`} className="text-secondary text-decoration-none">
            Next
            <FontAwesomeIcon className="fa-fw fa-lg text-secondary" icon={faAngleDoubleRight}></FontAwesomeIcon>
          </Link>
        }
      </div>
    </div>
  )
}

export default PrevNext;